<template>

    

    <div style="padding:20px;max-width:100%;overflow:auto;">


        <v-card class="" elevation="" max-width="" style="margin-top:20px;width:100%;max-width:100%;overflow:auto;word-break: break-all;" >
            
            <v-card-text >

                <div style="">
                    <h1 >Hits - Hotel Develop</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <p style="margin-bottom:20px!important;" ><b>URL</b>: https://develop.hitspms.net/</p>
                    <p style="margin-bottom:20px!important;" ><b>Login</b>: herlessi@economysoftware.com.br</p>
                    <p style="margin-bottom:20px!important;" ><b>senha</b>: economy160508!Q@W#E</p>
                    <p style="margin-bottom:20px!important;" ><b>Property Id</b>: 4601a1aa-350c-908e-95b1-a77c46025598</p>
                    <p style="margin-bottom:20px!important;" ><b>Tentant</b>: develop</p>
                    <p style="margin-bottom:20px!important;" ><b>Property Code</b>: 160</p>
                    <p style="margin-bottom:20px!important;" ><b>Secret</b>: hEzGHPzsyN9gnwmRhPZ7i4Z7QccYB26Yi7hwdEqLLYIoCrq8yEosGn6zqa9YV7PZcBTpJq5ap6NbdwXBzxbNODkd37dcJ/rPEGXQl35uV2tB0K/6ctK5xEBMZt6XFHlDEYVMQG4KZQECnVFSdgf9XWtPkcvlmoQ/Hf0o9BBZZfSg10TJ5ua6uRlDrYAl4vhzThpNe4pZAsLQVcUxlqQGUGzcW6HXvFiTU63NbsNhx8rJR5mVkD/tcTUtv3emH7lRf3U8LgNdpJt626j+hounTvNrSGm0MNnkWMi0D99aIdK2Td9htIVQi3Cd3XGYeef1rY/8sOIT1+9IjLQ2L2fUGg==</p>
                    <p style="margin-bottom:20px!important;" ><b>Scopes</b>:  [ "WebCheckIn", "PtOfSale", "InternetMgr", "RevenueMgr" ]</p>
                    
                </div>
               
            </v-card-text>

         </v-card>
            <!-- <v-card-text>

                <div style="">
                    <h1 >Hits - Hotel Equipotel</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <p style="margin-bottom:20px!important;" ><b>Property Id</b>: 1601bcaa-6bf2-19b5-ca02-a7fc8b61a875</p>
                    <p style="margin-bottom:20px!important;" ><b>Tentant</b>: equipotel</p>
                    <p style="margin-bottom:20px!important;" ><b>Property Code</b>: 1</p>
                    <p style="margin-bottom:20px!important;" ><b>Secret</b>: hEzGHPzsyN9gnwmRhPZ7i4Z7QccYB26Yi7hwdEqLLYIoCrq8yEosGn6zqa9YV7PZcBTpJq5ap6NbdwXBzxbNODkd37dcJ/rPEGXQl35uV2tB0K/6ctK5xEBMZt6XFHlDEYVMQG4KZQECnVFSdgf9XWtPkcvlmoQ/Hf0o9BBZZfSg10TJ5ua6uRlDrYAl4vhzThpNe4pZAsLQVcUxlqQGUGzcW6HXvFiTU63NbsNhx8rJR5mVkD/tcTUtv3emH7lRf3U8LgNdpJt626j+hounTvNrSGm0MNnkWMi0D99aIdK2Td9htIVQi3Cd3XGYeef1rY/8sOIT1+9IjLQ2L2fUGg==</p>
                    <p style="margin-bottom:20px!important;" ><b>Scopes</b>:  [ "WebCheckIn", "PtOfSale", "InternetMgr", "RevenueMgr" ]</p>
                    
                </div>
               
            </v-card-text>
            <hr style="margin-left:10px;margin-right:10px;max-width:100%;overflow:auto;"> -->

        <v-card class="" elevation="" max-width="" style="margin-top:20px;width:100%;max-width:100%;overflow:auto;word-break: break-all;" >

            <v-card-text>

                <div style="">
                    <h1 >Pagar me - Economy Software</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: https://id.pagar.me/signin</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Login</b>: : herlessi@gmail.com</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Senha</b>:  Economys@1980 </p>
                    
                </div>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Teste</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>numero da conta</b>: acc_o71ZnaMIeCb1n4GA</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Secret Key</b>: : sk_test_82eb2af608414bfba61a7f74a2c95375</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Nome da Conta</b>:  Economy Software Ltda (Conta 2) - test </p>
                    
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Producao</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>numero da conta</b>: acc_9Zw2a5rKS0iqOXJ6</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Secret Key</b>: sk_39940df0f7df4f3d95ad5bb0ab928170 </p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Nome da Conta</b>:   ECONOMY SOFTWARE LTDA PPGT  </p>
                    
                </div>
               
            </v-card-text>
               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;width:100%;max-width:100%;overflow:auto;word-break: break-all;" >

            <v-card-text>

                <div style="">
                    <h1>Cielo</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <br>
                    <h3>Acesso Desenvolvedor</h3><br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: 
                     <a target="_blank" href="https://desenvolvedores.cielo.com.br/api-portal/pt-br/user">Acesso Desenvolvedor</a></p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Login</b>: karynne@economysoftware.com.br</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Senha</b>:  --Precisa Recuperar a Senha -- </p>
                </div>
                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Acesso as APIs </h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Manual de Integração API e-commerce Cielo</b>: 
                        <a target="_blank" href="https://developercielo.github.io/manual/cielo-ecommerce">Documentações e tutoriais</a>
                    </p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Transação Simples</b>: 
                        <a target="_blank" href="https://developercielo.github.io/manual/cielo-ecommerce#transa%C3%A7%C3%A3o-simples">Transação Simples</a>
                    </p>                   
                </div>
                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Teste</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: https://apisandbox.cieloecommerce.cielo.com.br</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>MerchantId</b>: 35a221d3-6a56-4bf2-a1e0-e487f01a1919</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>MerchantKey</b>: HPDETMJNXBUGFNWZVQIZEUWLPGBRZBSEMAQACWPT</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Cartões para Testes</b>:</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >
                        <table style="border:1px solid black;">
                            <tr>
                                <td><b>Status Transação</b></td>
                                <td><b>Final do Cartão</b></td>
                                <td><b>Codigo Retorno</b></td>
                                <td><b>Mensagem Retorno</b></td>
                            </tr>
                            <tr>
                                <td>Autorizado</td>
                                <td>0000.0000.0000.0001  /  0000.0000.0000.0004</td>
                                <td> 4 / 6</td>
                                <td>Operação realizada com sucesso</td>
                            </tr>
                            <tr>
                                <td>Não Autorizado</td>
                                <td>0000.0000.0000.0002</td>
                                <td> 05</td>
                                <td>Não Autorizada</td>
                            </tr>                            
                            <tr>
                                <td>Não Autorizado</td>
                                <td>0000.0000.0000.0003</td>
                                <td> 57</td>
                                <td>Cartão Expirado</td>
                            </tr>                            
                            <tr>
                                <td>Não Autorizado</td>
                                <td>0000.0000.0000.0005</td>
                                <td> 78</td>
                                <td>Cartão Bloqueado</td>
                            </tr>                            
                            <tr>
                                <td>Não Autorizado</td>
                                <td>0000.0000.0000.0006</td>
                                <td>99</td>
                                <td>Time Out</td>
                            </tr>                            
                            <tr>
                                <td>Não Autorizado</td>
                                <td>0000.0000.0000.0007</td>
                                <td>77</td>
                                <td>Cartão Cancelado</td>
                            </tr>                            
                            <tr>
                                <td>Não Autorizado</td>
                                <td>0000.0000.0000.0008</td>
                                <td>70</td>
                                <td>Problemas com o Cartão de Crédito</td>
                            </tr>                            
                            <tr>
                                <td>Autorização Aleatória</td>
                                <td>0000.0000.0000.0009</td>
                                <td>99</td>
                                <td>Operation Successful / Time Out</td>
                            </tr>                            
                            
                        </table>
                    </p>
                </div>

                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Bandeiras</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >Visa / Master / Amex / Elo / Aura / JCB / Diners / Discover / Hipercard / Hiper</p>
                </div>
                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Producao</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>numero da conta</b>: https://api.cieloecommerce.cielo.com.br</p>
                </div>
                <br>
               
            </v-card-text>
               
        </v-card>      

        <v-card class="" elevation="" max-width="" style="margin-top:20px;width:100%;max-width:100%;overflow:auto;word-break: break-all;" >

            <v-card-text>

                <div style="">
                    <h1>GetNet</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <br>
                    <h3>Acesso Desenvolvedor</h3><br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: 
                     <a target="_blank" href="https://developers.getnet.com.br/dashboard#/dashboard">Acesso Desenvolvedor</a></p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Login</b>: karynne@economysoftware.com.br</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Senha</b>:  Economy1409* </p>
                </div>
                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Acesso as APIs </h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Acesso API</b>: 
                        <a target="_blank" href="https://developers.getnet.com.br/api">API</a>
                    </p>                
                </div>
                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Teste</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: https://api-sandbox.getnet.com.br</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>client_id</b>: b50f4837-b6fe-4bea-9748-7e9f79b268c8</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>client_secret </b>: bc1695e4-6816-476f-81d1-3d9c959b95dd</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Cartões para Testes</b>:
                        <a target="_blank" href="https://developers.getnet.com.br/api#section/Cartoes-para-Teste">Cartões</a>
                    </p>
                    <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                        <br>
                        <h4><span style="color:red;">Obsevação:</span> Ao utilizar os cartões de teste, é necessário ter atenção em alguns pontos</h4>
                        <ol>
                            <li>- Utilize uma data de vencimento válida, ou seja, posterior à data atual</li>
                            <li>- O nome do portador não deve ter caracteres especiais, como acento e Ç</li>
                            <li>- O valor do CVV pode ser qualquer valor numérico com três dígitos</li>
                            <li>- Para operações de tipo Débito, deve-se utilizar somente o cartão Visa: 4012001037141112.</li>
                        </ol>
                    </div>
                    <br>
                    
                    <p style="margin-bottom:20px!important;padding-left:20px;" >
                        <table style="border:1px solid black;">
                            <tr>
                                <td><b>Bandeira</b></td>
                                <td><b>Tipo de Teste</b></td>
                                <td><b>Resultado</b></td>
                                <td><b>Cartão</b></td>
                            </tr>
                            <tr>
                                <td>Master</td>
                                <td>Transação Autorizada</td>
                                <td>Transação Aprovada</td>
                                <td>5155901222280001</td>
                            </tr>
                            <tr>
                                <td>Master</td>
                                <td>Transação Não Autorizada</td>
                                <td>Cartão Inválido</td>
                                <td>5155901222270002</td>
                            </tr>
                            <tr>
                                <td>Master</td>
                                <td>Transação Não Autorizada</td>
                                <td>Cartão Vencido</td>
                                <td>5155901222260003</td>
                            </tr>
                            <tr>
                                <td>Master</td>
                                <td>Transação Não Autorizada</td>
                                <td>Estabelecimento Inválido</td>
                                <td>5155901222250004</td>
                            </tr>
                            <tr>
                                <td>Master</td>
                                <td>Transação Não Autorizada</td>
                                <td>Saldo Insuficiente</td>
                                <td>5155901222240005</td>
                            </tr>
                            <tr>
                                <td>Master</td>
                                <td>Transação Não Autorizada</td>
                                <td>Autorização Recusada</td>
                                <td>5155901222230006</td>
                            </tr>
                            <tr>
                                <td>Master</td>
                                <td>Transação Não Autorizada</td>
                                <td>Transacao Não Processada</td>
                                <td>5155901222220007</td>
                            </tr>
                            <tr>
                                <td>Master</td>
                                <td>Transação Não Autorizada</td>
                                <td>Excede o Limite de Retiradas</td>
                                <td>5155901222210008</td>
                            </tr>
                            <tr>
                                <td>Visa</td>
                                <td>Transação Autorizada</td>
                                <td>Transação Aprovada</td>
                                <td>4012001037141112</td>
                            </tr>
                                        
                            
                        </table>
                    </p>
                </div>
                <br>


                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Retornos Pagamentos</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >200 Pagamento efetuado com sucesso. Transação aprovada.</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >202 Solicitação de pagamento de crédito com confirmação tardia ou pré autorização recebida, aceita e armazenada esperando confirmação.</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >400 Requisição inválida.</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >401 Autenticação inválida</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >402 Requisição é válida mas pagamento negado. Pode ser negado por vários motivos / regras de negócios</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >404 Não encontrado</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >500 Erro interno do servidor ou serviço</p>
                </div>
                <br>



                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Producao</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>numero da conta</b>: https://api.getnet.com.br</p>
                </div>
                <br>
               
            </v-card-text>
               
        </v-card>            


        <v-card class="" elevation="" max-width="" style="margin-top:20px;width:100%;max-width:100%;overflow:auto;word-break: break-all;" >

            <v-card-text>

                <div style="">
                    <h1>E-Rede</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <br>
                    <h3>Acesso Desenvolvedor</h3><br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: 
                     <a target="_blank" href="https://developer.userede.com.br/">Acesso Desenvolvedor</a></p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Login</b>: diretoria@economysoftware.com.br</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Senha</b>: Economy123@</p>
                </div>
                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Tutorial de Testes</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Tutorial</b>: 
                        <a target="_blank" href="https://developer.userede.com.br/e-rede#tutorial-sandbox">Acessar</a>
                    </p>                
                </div>
                <br>


                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Tutorial de Testes</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Cartões</b>: 
                        <a target="_blank" href="https://developer.userede.com.br/e-rede#cartoes">Acessar</a>
                    </p>                
                </div>
                <br>


                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Teste</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: https://sandbox-erede.useredecloud.com.br</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Tipo</b>: Autenticação Basica</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>username (pv)</b>: 84214167</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>password (token) </b>: 89aca85969db4c62ab78e5d10d9d9c19</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Cartões para Testes</b>:
                        <a target="_blank" href="https://developers.getnet.com.br/api#section/Cartoes-para-Teste">Cartões</a>
                    </p>

                    
                </div>
                <br>



                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Seção de Autenticação no Portal</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >https://developer.userede.com.br/e-rede#autenticacao-autorizacao</p>
                </div>
                <br>


                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Producao</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >https://api.userede.com.br/erede</p>
                </div>
                <br>
               
            </v-card-text>
               
        </v-card>          

        <v-card class="" elevation="" max-width="" style="margin-top:20px;width:100%;max-width:100%;overflow:auto;word-break: break-all;" >

            <v-card-text>

                <div style="">
                    <h1>Stone</h1>
                </div>
                <!-- <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <br>
                    <h3>Acesso Desenvolvedor</h3><br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: 
                     <a target="_blank" href="https://developer.userede.com.br/">Acesso Desenvolvedor</a></p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Login</b>: diretoria@economysoftware.com.br</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Senha</b>: Economy123@</p>
                </div>
                <br>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Tutorial de Testes</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Tutorial</b>: 
                        <a target="_blank" href="https://developer.userede.com.br/e-rede#tutorial-sandbox">Acessar</a>
                    </p>                
                </div>
                <br> -->


                <!-- <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Tutorial de Testes</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Cartões</b>: 
                        <a target="_blank" href="https://developer.userede.com.br/e-rede#cartoes">Acessar</a>
                    </p>                
                </div>
                <br> -->


                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Teste</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: https://sandbox-auth-integration.stone.com.br</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Tipo</b>: Autenticação Basica</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>username (pv)</b>: 84214167</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>password (token) </b>: 89aca85969db4c62ab78e5d10d9d9c19</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Cartões para Testes</b>:
                        <a target="_blank" href="https://developers.getnet.com.br/api#section/Cartoes-para-Teste">Cartões</a>
                    </p>

                    
                </div>
                <br>



                <!-- <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Seção de Autenticação no Portal</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >https://developer.userede.com.br/e-rede#autenticacao-autorizacao</p>
                </div>
                <br> -->


                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3>Ambiente de Producao</h3>
                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" >https://e-commerce.stone.com.br</p>
                </div>
                <br>
               
            </v-card-text>
               
        </v-card>          

        <v-card class="" elevation="" max-width="" style="margin-top:20px;width:100%;max-width:100%;overflow:auto;word-break: break-all;" >

            <v-card-text>

                <div style="">
                    <h1 >Hub Stone</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">

                    <br>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>URL</b>: https://partnerhub.stone.com.br/#/home</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" ><b>Secret Key</b>: diretoria@economysoftware</p>
                    <p style="margin-bottom:20px!important;padding-left:20px;" v-if="usuariologado == 19" ><b>Senha</b>:  Economys@1980 </p>
                    
                </div>
                
               
            </v-card-text>
               
        </v-card>

    </div>

    
</template>

<script>

export default {
    name:'Parceiros',
    components: {
    },
    data(){
        return{
           usuariologado:'' 
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){
        this.usuariologado = localStorage.getItem('idusuario')
    }
    
}
</script>

<style scoped>
    table, th, td {
        border: 1px solid black;
    }

    .paragrafo {
        margin-bottom:20px!important;
    }
</style>